import React from "react";
import Header from "./Header/Header";
import Register from "../containers/Clients/Register";
const Layout = ({ children }) => {
  return (
    <>
      <div>
        <Register />
        <Header children={children} />
      </div>
    </>
  );
};

export default Layout;
