import { makeStyles } from "@mui/styles";
import MethodGet from "../../config/Service";
import { useForm } from "react-hook-form";
import OrdersContext from "../../Context/Orders/OrdersContext";
import { useContext, useEffect, useState } from "react";
import SelectPayment from "./SelectPayment";

import {
  List,
  ListItem,
  Grid,
  Card,
  Divider,
  Button,
  Typography,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import Layout from "../../components/Layout";
import TrashIcon from "../../components/icons/TrashIcon";
import BackIcon from "../../components/icons/BackIcon";
import PaymentDiscountCheckbox from "./PaymentDiscountCheckbox";
import FormatCurrency from "../../utils/FormatCurrency";

const useStyles = makeStyles({
  img: {
    width: 100,
    height: 100,
  },
  customScrollbar: {
    width: "100%",
    height: "500px",
    overflowY: "scroll",
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#fff",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar": {
      width: "10px",
      backgroundColor: "#fff",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundImage: `repeating-linear-gradient(
        45deg,

        #FF0000 25%,
        #FF0000 25%,
        #FFFFFF 35%,
        #FFFFFF 30%
      )`,
    },
  },
});

export default function ProductsList(props) {
  const classes = useStyles();

  const eliminarProducto = (value) => {
    const todosProducto = cart.filter((c) => c.product_id !== value);
    saveCart(todosProducto);
    localStorage.setItem("cart", JSON.stringify(todosProducto));
  };

  let cartLocal = localStorage.getItem("cart");
  const [cart, saveCart] = useState(cartLocal ? JSON.parse(cartLocal) : []);
  const [descuento, guardarDescuento] = useState(0);
  const [subtotal, guardarSubtotal] = useState(0);
  const [total, guardarTotal] = useState(0);
  const [regla, saveRegla] = useState({});
  const [cash, setCash] = useState(null);
  // const detectarCambiosCash = (value) => {
  //   setCash(value.value);
  // };

  const subtotals = cart.map((p) => Number(p.final_price));
  const sumaSubtotal = subtotals.reduce((acc, curr) => acc + curr, 0);

  useEffect(() => {
    guardarSubtotal(sumaSubtotal);
  }, [sumaSubtotal]);

  useEffect(() => {
    let url = `/Discount/${subtotal}`;
    MethodGet(url)
      .then((res) => {
        guardarDescuento(Number(res.data.discount));
        saveRegla(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [subtotal]);

  const detectarCambiosCash = (value) => {
    setCash(value);
    if (value === "si") {
      if (descuento === 45) {
        guardarDescuento(50); // Sumar 5% para llegar a 50%
      } else {
        alert(
          "El descuento debe ser exactamente 45% para aplicar la opción de efectivo."
        );
      }
    } else {
      if (descuento === 50) {
        guardarDescuento(45); // Restar 5% para regresar a 45%
      }
    }
  };

  useEffect(() => {
    let sumaFinal = subtotal - (subtotal * descuento) / 100;
    guardarTotal(sumaFinal);
  }, [subtotal, descuento]);

  const elCliente = localStorage.getItem("cliente");
  const client = JSON.parse(elCliente);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const { CreateOrder } = useContext(OrdersContext);
  const onSubmit = (data) => {
    data.subtotal = subtotal;
    data.total = total;
    data.products_list = cart;
    data.status = 0;
    data.id_client = client.id;
    data.id_business_rule = regla.id;
    data.descuento = descuento;
    data.aditional = cash;
    CreateOrder(data);
    saveCart([]);
    // localStorage.removeItem("cart");
  };

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography fontSize={40} textAlign='center'>
            Carrito de compras
          </Typography>
        </Grid>
        <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
          <IconButton onClick={() => props.history.goBack()}>
            <BackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter")
                e.preventDefault();
            }}
            autoComplete='off'
          >
            <div className={classes.customScrollbar}>
              <List sx={{ width: "100%" }}>
                {cart.map((item, index) => (
                  <ListItem key={index}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Card>
                          <Grid container spacing={2} sx={{ p: 2 }}>
                            <Grid item xs={3}>
                              <Typography fontWeight='bold'>
                                <img
                                  src={item.image}
                                  width={150}
                                  height={150}
                                />
                              </Typography>
                            </Grid>
                            <Grid item xs={9}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <Typography fontWeight='bold'>
                                    {item.name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  Precio U: {FormatCurrency(item.price)}
                                </Grid>
                                <Grid item xs={4} sx={{ color: "green" }}>
                                  {item.stock} disponibles!
                                </Grid>
                                <Grid item xs={4}>
                                  Subtotal:{" "}
                                  {FormatCurrency(item.price * item.cantidad)}
                                </Grid>
                                <Grid item xs={5} sx={{ color: "#fb8c00" }}>
                                  {item.cantidad} pzas en carrito
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  sx={{ color: "#01579b", fontWeight: "bold" }}
                                >
                                  Marca:{" "}
                                  {item.brand_id === 13
                                    ? "Wapizima"
                                    : item.brand_id === 20
                                    ? "Aurora"
                                    : item.brand_id === 22
                                    ? "Golden Nails"
                                    : item.brand_id === 23 && "MerryColor"}
                                </Grid>
                                <Grid item xs={3}>
                                  <IconButton
                                    onClick={() =>
                                      eliminarProducto(item.product_id)
                                    }
                                  >
                                    <TrashIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
              </List>
            </div>

            {/* {descuento >= 40 && (
              <PaymentDiscountCheckbox
                subtotal={subtotal}
                descuento={descuento}
                guardarDescuento={guardarDescuento}
              />
            )} */}
            {subtotal > 7999 && (
              <div style={{ padding: 2, marginBottom: 3 }}>
                <Typography sx={{ marginTop: 2, marginBottom: 2 }}>
                  ¿Tu pago será en efectivo?
                </Typography>
                <Select
                  value={cash || ""}
                  onChange={(e) => detectarCambiosCash(e.target.value)}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value='' disabled>
                    Seleccione una opción
                  </MenuItem>
                  <MenuItem value='si'>Efectivo</MenuItem>
                  <MenuItem value='no'>Tarjeta</MenuItem>
                </Select>
              </div>
            )}
            <List>
              <ListItem
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography>Subtotal:</Typography>
                <Typography>{FormatCurrency(subtotal)}</Typography>
              </ListItem>
              <Divider />
              <ListItem
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography>Descuento</Typography>
                <Typography>{descuento} %</Typography>
              </ListItem>
              <Divider />
              <ListItem
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography>Total</Typography>
                <Typography>{FormatCurrency(total)}</Typography>
              </ListItem>
            </List>
            <List>
              <ListItem>
                <Button
                  fullWidth
                  variant='contained'
                  type='submit'
                  sx={{
                    backgroundColor: "#f48fb1",
                    color: "white",
                    "&:hover": { backgroundColor: "#f48fb1", color: "white" },
                  }}
                >
                  Finalizar compra
                </Button>
              </ListItem>
            </List>
          </form>
        </Grid>
      </Grid>
    </Layout>
  );
}
