import React from "react";

const BackIcon = () => {
  return (
    <svg
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      fill='#000000'
      height={50}
    >
      <g id='SVGRepo_bgCarrier' />
      <g id='SVGRepo_tracerCarrier' />
      <g id='SVGRepo_iconCarrier'>
        {" "}
        <defs>
          {" "}
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".cls-1{fill:#da3380;}.cls-2{fill:#f55fa6;}.cls-3{fill:#6c2e7c;}",
            }}
          />{" "}
        </defs>{" "}
        <g id='Icons'>
          {" "}
          <path
            className='cls-1'
            d='M23,12A11,11,0,0,1,1,12a10.827,10.827,0,0,1,.29-2.5,11,11,0,0,1,21.42,0A10.827,10.827,0,0,1,23,12Z'
          />{" "}
          <ellipse className='cls-2' cx={12} cy='9.5' rx='10.71' ry='8.5' />{" "}
        </g>{" "}
        <g data-name='Layer 4' id='Layer_4'>
          {" "}
          <path
            className='cls-3'
            d='M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z'
          />{" "}
          <path
            className='cls-3'
            d='M18,11H8.414l2.293-2.293A1,1,0,1,0,9.293,7.293l-4,4a1,1,0,0,0,0,1.414l4,4a1,1,0,0,0,1.414-1.414L8.414,13H18a1,1,0,0,0,0-2Z'
          />{" "}
        </g>{" "}
      </g>
    </svg>
  );
};

export default BackIcon;
