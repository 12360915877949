import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/Layout";
import MethodGet from "../../config/Service";
import TableSales from "../Control/TableSales";
import { Grid, Typography, CircularProgress } from "@mui/material";
import OrdersContext from "../../Context/Orders/OrdersContext";
const MyOrders = (props) => {
  const { PdfOrder, MakeAsReady } = useContext(OrdersContext);
  const { id } = props.match.params;
  const [sales, setSales] = useState();
  useEffect(() => {
    let url = `/salesByClient/${id}`;
    MethodGet(url)
      .then((res) => {
        setSales(res.data.sales);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const [isLoading, setIsLoading] = useState(false); // Estado para el spinner

  return (
    <Layout>
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              fontWeight='bold'
              fontFamily='monospace'
              variant='h4'
              textAlign='center'
            >
              Mis Compras
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{ display: "flex", justifyContent: "center", padding: 2 }}
        >
          {isLoading ? (
            <CircularProgress /> // Muestra el spinner mientras se realiza la búsqueda
          ) : (
            <>
              {sales && sales.length > 0 ? (
                <TableSales
                  orders={sales} // Pasa las órdenes filtradas o todas según el estado
                  PdfOrder={PdfOrder}
                  MakeAsReady={MakeAsReady}
                  client={true}
                />
              ) : (
                <Typography>
                  No se encontraron registros de ventas para esta cliente!
                </Typography>
              )}
            </>
          )}
        </Grid>
      </>
    </Layout>
  );
};

export default MyOrders;
