import { ADD_MULTIMEDIA_PRODUCT, GET_ALL_PRODUCTS } from "../../types";

export default function ProductsReducer(state, action) {
  switch (action.type) {
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case ADD_MULTIMEDIA_PRODUCT:
      return {
        ...state,
        products: state.products.map((product) =>
          product.id === action.payload.id ? { ...action.payload } : product
        ),
        success: true,
        ErrorsApi: [],
      };
    default:
      return state;
  }
}
