import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import CartIcon from "../icons/CartIcon";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Badge from "@mui/material/Badge";

const pages = ["Marcas"];

function ResponsiveAppBar({ children }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // Cargar el carrito desde localStorage al iniciar
  const cartLocal = localStorage.getItem("cart");
  let cart = JSON.parse(cartLocal);
  let cliente;

  const clienteLocal = localStorage.getItem("cliente");
  cliente = clienteLocal ? JSON.parse(clienteLocal) : null;

  const routesByClientName = {
    AdminWapizima: "/VentasWapizima",
    AdminGolden: "/VentasGolden",
    AdminMerryColor: "/VentasMerryColor",
    AdminAurora: "/VentasAurora",
  };
  return (
    <div>
      <AppBar
        position='fixed'
        sx={{ backgroundColor: "#FEB6DB", width: "100%", height: 110 }}
      >
        <Container maxWidth='xl'>
          <Toolbar disableGutters>
            <Typography
              variant='h6'
              noWrap
              component='a'
              href='/'
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img
                src={require("../../assets/img/logo christmas 2.webp")}
                style={{ display: { xs: "none", md: "flex" }, mr: 1, mb: 4 }}
              />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleOpenNavMenu}
                color='inherit'
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{ display: { xs: "block", md: "none" } }}
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link
                    to='/'
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Typography sx={{ textAlign: "center" }}>Marcas</Typography>
                  </Link>
                </MenuItem>

                {cliente && (
                  <MenuItem>
                    {cliente && (
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={`/mis-compras/${cliente.id}`}
                      >
                        <Typography>mis compras</Typography>
                      </Link>
                    )}
                  </MenuItem>
                )}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                <Link to='/' style={{ textDecoration: "none", color: "white" }}>
                  Marcas
                </Link>
              </Button>
              {cliente && (
                <Button>
                  {cliente && (
                    <Link
                      style={{ textDecoration: "none", color: "white" }}
                      to={`/mis-compras/${cliente.id}`}
                    >
                      <Typography>mis compras</Typography>
                    </Link>
                  )}
                </Button>
              )}
            </Box>
            <Box sx={{ flexGrow: 0, marginTop: 4 }}>
              <Link to={"/carrito"}>
                <Badge badgeContent={cart ? cart.length : 0} color='primary'>
                  <CartIcon />
                </Badge>
              </Link>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <div style={{ marginTop: 110 }}>{children}</div>
    </div>
  );
}

export default ResponsiveAppBar;
