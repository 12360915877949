import React from "react";
import ProductsReducer from "./ProductsReducer";
import ProductsContext from "./ProductsContext";
import MethodGet, { MethodPost } from "../../config/Service";
import { useReducer } from "react";
import { ADD_MULTIMEDIA_PRODUCT, GET_ALL_PRODUCTS } from "../../types";
import clienteAxios from "../../config/Axios";
import headerConfig from "../../config/imageHeaders";
import Swal from "sweetalert2";
const ProductsState = ({ children }) => {
  const initialState = {
    products: [],
    ErrorsApi: [],
    success: false,
  };
  const [state, dispatch] = useReducer(ProductsReducer, initialState);
  const GetAllProducts = (id) => {
    let url = `/productsbrands/${id}`;
    clienteAxios
      .get(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_PRODUCTS,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const AddMultimediaProduct = (data) => {
    Swal.fire({
      title: "Agregar Imagen",
      text: "¿Estas seguro de Agregar esta imagen como foto del producto?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Aceptar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        const formData = new FormData();
        formData.append("id_product", data.id_product);
        formData.append("image", data.image);
        let url = `/Images`;
        Swal.fire({
          title: "Subiendo imagen...!",
          showConfirmButton: false,
          showLoading: true,
        });
        Swal.showLoading();
        MethodPost(url, formData, { headerConfig })
          .then((res) => {
            Swal.fire({
              title: "Agregado",
              text: "La imagen se ha agregado correctamente!",
              showConfirmButton: false,
              timer: 1000,
              icon: "success",
            });
            // setTimeout(() => {
            //   window.location.href = "/PropertiesPending";
            // }, 1000);
            dispatch({
              type: ADD_MULTIMEDIA_PRODUCT,
              payload: res.data,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              icon: "error",
              text: error.response.data.message,
            });
          });
      }
    });
  };

  return (
    <ProductsContext.Provider
      value={{
        products: state.products,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        GetAllProducts,
        AddMultimediaProduct,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export default ProductsState;
