import React from "react";
import { Card, Typography, CardMedia, CardContent } from "@mui/material";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const BrandCard = ({ brand }) => {
  return (
    <Card
      sx={{
        width: "100%",
        borderTopRightRadius: 10,
        borderTopLeftRadius: 80,
        borderBottomRightRadius: 80,
      }}
    >
      <Link
        to={`/products/${brand.id}`}
        style={{ textDecoration: "none", color: "black" }}
      >
        <CardMedia
          sx={{ height: { xs: 200, md: 400 } }}
          image={brand.image}
          title={brand.name}
        />
      </Link>
    </Card>
  );
};

export default BrandCard;
