import React from "react";
import ReactDOM from "react-dom";
import CatalogoApp from "./CatalogoApp";

ReactDOM.render(
  <React.StrictMode>
    <CatalogoApp />
  </React.StrictMode>,
  document.getElementById("root")
);
