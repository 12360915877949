import {
  GET_ALL_ORDER,
  GET_ALL_ORDERS_COMPLETED,
  GET_ALL_ORDER_BRAND,
  MAKE_AS_READY,
} from "../../types";

export default function OrdersReducer(state, action) {
  switch (action.type) {
    case GET_ALL_ORDER:
      return {
        ...state,
        orders: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_ALL_ORDER_BRAND:
      return {
        ...state,
        orders: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_ALL_ORDERS_COMPLETED:
      return {
        ...state,
        ordersCompleted: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case MAKE_AS_READY:
      return {
        ...state,
        orders: state.orders.filter((order) => order.id !== action.payload),
      };
    default:
      return state;
  }
}
