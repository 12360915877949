import { Grid } from "@material-ui/core";
import {
  TextField,
  CircularProgress,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import CardProduct from "../../components/Products/CardProduct";
import Swal from "sweetalert2";
import ProductsContext from "../../Context/Products/ProductsContext";
import { useDebounce } from "use-debounce";
import NoDataComponent from "../../components/loading/NoDataComponents";
import NoResultsComponent from "../../components/loading/NoResultsComponent";
import Layout from "../../components/Header/Header";
import BackIcon from "../../components/icons/BackIcon";

const Index = (props) => {
  const { id } = props.match.params;
  const [cargando, spinerCargando] = useState(false);
  const [loading, setLoading] = useState(true);

  const { GetAllProducts, products } = useContext(ProductsContext);
  useEffect(() => {
    spinerCargando(true);
    setLoading(true); // Activa el estado de carga
    GetAllProducts(id);
    setTimeout(() => {
      setLoading(false); // Desactiva el estado de carga después de 2 segundos
      spinerCargando(false);
    }, 2000);
  }, [id]);
  const cartLocal = localStorage.getItem("cart");
  //armado del carrito
  const [cart, saveCart] = useState(cartLocal ? JSON.parse(cartLocal) : []);

  //funcion para buscador
  const [magicWord, setMagicWord] = useState("");
  const [debounceWord] = useDebounce(magicWord, 1500);
  const [searchProducts, saveSearchProducts] = useState([]);

  const handleSearchProduct = () => {
    try {
      let searchResult = products.filter((product) =>
        product.name
          .toString()
          .toLowerCase()
          .includes(debounceWord.toLowerCase())
      );
      saveSearchProducts(searchResult);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "No se encontraron registros que coincidan con los criterios de busqueda!",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    saveSearchProducts(products);
  }, []);

  useEffect(() => {
    handleSearchProduct();
  }, [debounceWord]);

  return (
    <Layout>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: 4,
              marginRight: 5,
            }}
          >
            <IconButton onClick={() => props.history.goBack()}>
              <BackIcon />
            </IconButton>
          </Grid>
          <Grid container spacing={2} style={{ padding: 6, marginTop: 2 }}>
            {products.length > 0 && (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  label='Buscar producto por nombre'
                  inputProps={{ "aria-label": "search" }}
                  name='search'
                  onChange={(e) => {
                    setMagicWord(e.target.value);
                    saveSearchProducts(null);
                  }}
                />
              </Grid>
            )}
          </Grid>
          {!magicWord &&
            (products.length === 0 ? (
              <>
                <NoDataComponent />
                <Typography variant='h4' color='initial' textAlign='center'>
                  No se encontraron productos en esta sección
                </Typography>
              </>
            ) : (
              <Grid container spacing={2}>
                {products.map((product, index) => (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 4,
                    }}
                  >
                    <CardProduct
                      product={product}
                      cart={cart}
                      saveCart={saveCart}
                    />
                  </Grid>
                ))}
              </Grid>
            ))}
          {magicWord &&
            (!searchProducts ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>
            ) : searchProducts.length === 0 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography variant='body1' fontSize='25px'>
                  No se encontrarón resultados que coincidan con los criterios
                  de busqueda!
                </Typography>
              </div>
            ) : (
              <Grid container spacing={2}>
                {searchProducts.map((product, index) => (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={4}
                    lg={3}
                    xl={3}
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 4,
                    }}
                  >
                    <CardProduct
                      product={product}
                      cart={cart}
                      saveCart={saveCart}
                    />
                  </Grid>
                ))}
              </Grid>
            ))}
        </>
      )}
    </Layout>
  );
};

export default Index;
