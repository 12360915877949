import { Route, Switch } from "react-router-dom";
import React from "react";
//-------------Usuarios---------------------------------------------//
import Control from "../containers/Control/Control";
import ShowOrder from "../containers/Control/ShowOrder";
import Completed from "../containers/Control/Completed";
import ProductsBrands from "../containers/Products/ProductsBrands";
import IndexBrands from "../containers/Brands/IndexBrands";
import MyOrders from "../containers/Clients/MyOrders";
export const EcommerceRoutes = () => {
  return (
    <Switch>
      <Route exact path='/' component={IndexBrands} />
      <Route exact path='/products/:id' component={ProductsBrands} />
      <Route exact path='/control' component={Control} />
      <Route exact path='/Venta/:id' component={ShowOrder} />
      <Route exact path='/Ventas' component={Completed} />
      <Route exact path='/mis-compras/:id' component={MyOrders} />
    </Switch>
  );
};
