import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Grid, TextField, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { MethodPost } from "../../config/Service";
import { useState } from "react";
import Swal from "sweetalert2";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#ad1457",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ad1457",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#ad1457",
    },
    "&:hover fieldset": {
      borderColor: "#ad1457",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ad1457",
    },
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function Register() {
  const [open, setOpen] = React.useState(false);
  const clientLocal = localStorage.getItem("cliente");
  const [cliente, saveClient] = useState(
    clientLocal ? JSON.parse(clientLocal) : null
  );

  setTimeout(() => {
    if (cliente === null) {
      setOpen(true);
    }
  }, 1000);

  const handleClose = (e, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    let url = "/Clients";
    MethodPost(url, data)
      .then((res) => {
        localStorage.setItem("cliente", JSON.stringify(res.data));
        saveClient(res.data);
        Swal.fire({
          title: "Registrado",
          text: "Se ha registrado correctamente!",
          icon: "success",
          timer: 2500,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {cliente === null && (
        <Dialog
          onClose={handleClose}
          aria-labelledby='customized-dialog-title'
          open={open}
          disableEscapeKeyDown
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              p: 0,
              bgcolor: "rgba(255, 255, 255, 0.1)", // Fondo semitransparente para el efecto glass
              backdropFilter: "blur(10px)", // Efecto de desenfoque
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)", // Sombra para profundidad
              borderRadius: 0,
              maxWidth: 800,
              mx: "auto",
              mt: "0%",
              textAlign: "center",
              borderTopRightRadius: 80,
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                backgroundImage:
                  'url("https://i.pinimg.com/736x/ab/58/44/ab58444ff790f1a6e80c415a2e6b42d4.jpg")', // Cambia la URL por tu imagen deseada
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: 2,
                p: 0,
              }}
            >
              <DialogTitle
                sx={{
                  m: 0,
                  p: 2,
                  textAlign: "center",
                  color: "#ad1457",
                  mt: 3,
                }}
                id='customized-dialog-title'
              >
                Registro Christmas Nails 2024
              </DialogTitle>
              <DialogContent dividers>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                  <Grid
                    container
                    spacing={2}
                    sx={{ display: "flex", justifyContent: "end" }}
                  >
                    <Grid item xs={10}>
                      <CssTextField
                        label='Nombre Completo'
                        fullWidth
                        name='name'
                        type='text'
                        error={!!errors.name}
                        helperText={errors.name ? errors.name.message : ""}
                        {...register("name", {
                          required: {
                            value: true,
                            message: "El nombre es requerido",
                          },
                          pattern: {
                            value: /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]+$/,
                            message: "Únicamente caracteres alfabéticos",
                          },
                          minLength: {
                            value: 2,
                            message: "Se requieren mínimo 2 caracteres",
                          },
                          maxLength: {
                            value: 45,
                            message: "Máximo 45 caracteres",
                          },
                        })}
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <CssTextField
                        fullWidth
                        label='Teléfono'
                        type='Text'
                        name='number_phone'
                        error={!!errors.number_phone}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                        helperText={
                          errors.number_phone ? errors.number_phone.message : ""
                        }
                        {...register("number_phone", {
                          required: "El teléfono es requerido",
                          minLength: {
                            value: 10,
                            message:
                              "El teléfono debe tener al menos 10 dígitos",
                          },
                          maxLength: {
                            value: 10,
                            message:
                              "El teléfono debe tener como máximo 10 dígitos",
                          },
                          pattern: {
                            value: /^\d*$/,
                            message: "Solo se permiten datos numéricos",
                          },
                        })}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        type='submit'
                        sx={{
                          backgroundColor: "#d81b60",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#d81b60",
                            color: "white",
                          },
                        }}
                      >
                        Registrarme
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </DialogContent>
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  );
}
