import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Box, Button, Grid, Tooltip } from "@mui/material";
import Swal from "sweetalert2";
import "react-slideshow-image/dist/styles.css";
import ModalImage from "react-modal-image-responsive";
import showToast from "../../utils/ShowToast";
import { useState } from "react";
import AddImageProduct from "../../containers/Products/AddImageProduct";
import PriceFormat from "../../utils/FormatCurrency";
import NotificationDemo from "../Notifications/Notifications";
const useStyles = makeStyles({
  img: {
    width: 200,
    height: 150,
  },
  jss59: {
    width: "20%",
    border: "1px solid #bbb",
    height: "15%",
    outline: "none",
    padding: "15px 10px",
    marginLeft: "10px",
    borderRadius: "10px",
  },
});
export default function CardProduct({ product, cart, saveCart }) {
  const classes = useStyles();
  const { img } = product;

  const agregarProductsState = (prod) => {
    Swal.fire({
      title: "¿Cuántas piezas deseas agregar?",
      allowOutsideClick: false,
      html: `<input type="number" id="quantity" class="swal2-input" placeholder="1258" min="1" oninput="this.value = this.value.replace(/[^0-9]/g, '')">`,
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Guardar",
      focusConfirm: false,
      preConfirm: () => {
        const quantity = parseInt(
          Swal.getPopup().querySelector("#quantity").value,
          10
        );
        if (!quantity || quantity <= 0) {
          Swal.showValidationMessage("La cantidad debe ser mayor a 0");
          return false;
        }
        return { quantity };
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        const quantityToAdd = result.value.quantity;
        const existingProductIndex = cart.findIndex(
          (item) => item.product_id === prod.id
        );

        let updatedCart = [...cart];

        if (existingProductIndex === -1) {
          // Producto nuevo al carrito
          const productoResultado = {
            product_id: prod.id,
            barcode: prod.tag,
            price: Number(prod.price_purchase),
            stock: prod.stock,
            name: prod.name,
            image: prod.image,
            brand_id: prod.brand_id,
            cantidad: quantityToAdd,
            final_price: prod.price_purchase * quantityToAdd,
            image: prod?.img[0]?.image ?? [],
          };
          if (productoResultado.cantidad > prod.stock) {
            showToast("warning", "¡Lo sentimos!", "El stock es insuficiente");
            return;
          }

          updatedCart = [productoResultado, ...cart];
        } else {
          // Producto existente, actualiza la cantidad
          const existingProduct = updatedCart[existingProductIndex];
          const newQuantity = Number(quantityToAdd);
          if (newQuantity > prod.stock) {
            showToast("warning", "¡Lo sentimos!", "El stock es insuficiente");
            return;
          }

          existingProduct.cantidad = newQuantity;
          existingProduct.final_price = newQuantity * existingProduct.price;
        }

        // Actualizar carrito en localStorage y estado
        localStorage.setItem("cart", JSON.stringify(updatedCart));
        saveCart(updatedCart);

        showToast(
          "success",
          "Producto agregado",
          `Piezas agregadas: ${quantityToAdd}`
        );
      }
    });
  };

  const productoEnCarrito = cart.some((item) => item.product_id === product.id);

  const [modalMultimedia, openModalMultimedia] = useState(false);
  const [id_product, setIdProduct] = useState(null);
  const handleOpenMultimedia = (id) => {
    openModalMultimedia(true);
    setIdProduct(id);
  };
  const handleCloseMultimedia = () => {
    openModalMultimedia(false);
    setIdProduct(null);
  };

  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {img.length > 0 ? (
          <Box sx={{ width: "100%", display: "flex" }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box sx={{ position: "obsolute" }}>
                  <ModalImage
                    className={classes.imgproduct}
                    small={img[0]?.image}
                    large={img[0]?.image}
                    alt={product.name}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box sx={{ width: "100%", display: "flex" }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box sx={{ position: "relative" }}>
                  <ModalImage
                    className={classes.imgproduct}
                    small={require("../../assets/img/default .png")}
                    large={require("../../assets/img/default .png")}
                    alt={product.name}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        <Box>
          <Typography
            fontSize={{ xs: 15, sm: 20 }}
            textAlign={"justify"}
            sx={{ padding: 2 }}
          >
            {product.name}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography fontWeight='bold' fontFamily='monospace' fontSize='20px'>
            {PriceFormat(product.price_purchase)}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end", mr: 1 }}>
          <Typography
            sx={{
              fontSize: { xs: "12px", sm: "18px" },
              color: "green",
              textAlign: "end",
            }}
          >
            {product.stock} Disponibles!
          </Typography>
        </Box>
        <CardActions
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            spacing={2}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <>
                {productoEnCarrito ? (
                  <Button
                    variant='contained'
                    sx={{
                      color: "white",
                      backgroundColor: "#004d40",
                      "&:hover": {
                        color: "white",
                        backgroundColor: "#004d40",
                      },
                    }}
                    onClick={() => agregarProductsState(product)}
                  >
                    Modificar
                  </Button>
                ) : (
                  <Button
                    variant='contained'
                    sx={{
                      color: "white",
                      backgroundColor: "#ad1457",
                      "&:hover": {
                        color: "white",
                        backgroundColor: "#ad1457",
                      },
                    }}
                    onClick={() => agregarProductsState(product)}
                  >
                    Agregar
                  </Button>
                )}
              </>
            </Grid>
            <Grid
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 3,
              }}
            >
              {img.length === 0 && (
                <Tooltip title='Actualizar multimedia' placement='top'>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => handleOpenMultimedia(product.id)}
                  >
                    Subir foto
                  </Button>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      {id_product !== null && (
        <AddImageProduct
          open={modalMultimedia}
          handleClose={handleCloseMultimedia}
          id={id_product}
        />
      )}
    </>
  );
}
