export const types = {
  MOSTRAR_ALERTA: "[AUTH] mostrar alerta",
  OCULTAR_ALERTA: "[AUTH] ocultar alerta",
  LOGIN_ERROR: "[AUTH] login error",
  LOGIN_EXITOSO: "[AUTH] login existoso",
  OBTENER_USUARIO: "[AUTH] obtener usuario",
  CERRAR_SESION: "[AUTH] cerrar sesion",
  INICIAR_SESION: "[AUTH] inicia sesion",
  USER_CHANGEPHOTO: "[AUTH] USER_CHANGEPHOTO",
};

export const GET_ALL_ORDER = "GET_ALL_ORDERS";
export const GET_ALL_ORDER_BRAND = "GET_ALL_ORDER_BRAND";
export const MAKE_AS_READY = "MAKE_AS_READY";
export const CREATE_ORDER = "CREATE_ORDER";
export const GET_ALL_ORDERS_COMPLETED = "GET_ALL_ORDER_COMPLETED";

export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const ADD_MULTIMEDIA_PRODUCT = "ADD_MULTIMEDIA_PRODUCT";

//brands
export const GET_ALL_BRANDS = "GET_ALL_BRANDS";
export const ADD_BRANDS = "ADD_BRANDS";
export const UPDATE_BRANDS = "UPDATE_BRANDS";
export const DELETE_BRANDS = "DELETE_BRANDS";
export const CURRENT_BRAND = "CURRENT_BRAND";

//Errors API
export const SHOW_ERRORS_API = "SHOW_ERRORS_API";
