import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import {
  Button,
  Grid,
  Typography,
  CircularProgress,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import TableSales from "./TableSales";
import OrdersContext from "../../Context/Orders/OrdersContext";
import { useContext } from "react";
import Search from "./SearchOrder";
import { AdminWapizima } from "../../utils/UserAdmin";
import MethodGet from "../../config/Service";
import PriceFormat from "../../utils/FormatCurrency";
const ControlW = () => {
  // useEffect(() => {
  //   AdminWapizima();
  // }, []);
  const [total_sales, setTotalSales] = useState(0);
  const [sales, setSales] = useState([]);
  const [pricePublic, setPricePublic] = useState();
  useEffect(() => {
    let url = `/salesByBrand/${13}`;
    MethodGet(url)
      .then((res) => {
        setTotalSales(res.data.totalSalesAmount);
        setSales(res.data.sales);
        setPricePublic(res.data.totalPricePublic);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const { PdfOrder, MakeAsReady, SalesOfDay } = useContext(OrdersContext);

  const [filteredData, setFilteredData] = useState(sales);
  const [isLoading, setIsLoading] = useState(false); // Estado para el spinner

  // Función para manejar los resultados de búsqueda
  const handleSearchResults = (results) => {
    setFilteredData(results);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontWeight='bold'
            fontFamily='monospace'
            variant='h4'
            textAlign='center'
          >
            Control de ventas
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Card sx={{ boxShadow: 4 }}>
            <CardHeader title='Total de Precio Publico' />
            <CardContent>{PriceFormat(pricePublic ?? 0)}</CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card sx={{ boxShadow: 4 }}>
            <CardHeader title='Total de vendido' />
            <CardContent>{PriceFormat(total_sales ?? 0)}</CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card sx={{ boxShadow: 4 }}>
            <CardHeader title='Total de ventas' />
            <CardContent>{sales ? sales.length : 0}</CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "center", padding: 2 }}
      >
        <Grid item xs={12} sx={{ justifyContent: "center" }}>
          <Search
            orders={sales}
            onSearch={handleSearchResults}
            setIsLoading={setIsLoading} // Pasa setIsLoading correctamente aquí
          />
        </Grid>
        {isLoading ? (
          <CircularProgress /> // Muestra el spinner mientras se realiza la búsqueda
        ) : (
          <>
            {filteredData.length > 0 ? (
              <TableSales
                orders={filteredData} // Pasa las órdenes filtradas o todas según el estado
                PdfOrder={PdfOrder}
                MakeAsReady={MakeAsReady}
                client={false}
              />
            ) : (
              <Typography>
                No se encontraron registros de ventas para esta marca!
              </Typography>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default ControlW;
