import { useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { PublicRouter } from "./PublicRoutes";
import { PrivateRouter } from "./PrivateRoute";
import { EcommerceRoutes } from "./CatalogoRoutes";
import AuthContext from "../Context/auth/AuthContext";
import Control from "../containers/Control/Control";
import { Box, Grid } from "@mui/material";
// import TypeUsers from "../containers/type_users/TypeUsers";
import SpinnerComponent from "../components/loading/SpinnerComponent";
import ShowOrder from "../containers/Control/ShowOrder";
import Completed from "../containers/Control/Completed";
import ProductsBrands from "../containers/Products/ProductsBrands";
import IndexBrands from "../containers/Brands/IndexBrands";
import ProductsList from "../containers/ProductsList/ProductsList";
import ControlW from "../containers/Control/ControlW";
import ControlM from "../containers/Control/ControlM";
import ControlA from "../containers/Control/ControlA";
import ControlG from "../containers/Control/ControlG";
import MyOrders from "../containers/Clients/MyOrders";
export default function AppRouter() {
  const { autenticado, usuarioAutenticado, cargando } = useContext(AuthContext);

  useEffect(() => {
    usuarioAutenticado();
  }, []);
  if (cargando) {
    return (
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box
          sx={{
            width: "105%",
            height: "177%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SpinnerComponent />
        </Box>
      </Grid>
    );
  }
  return (
    <>
      <Router>
        <Switch>
          <PublicRouter
            exact
            path='/'
            component={IndexBrands}
            isAuthenticated={autenticado}
          />
          <PublicRouter
            exact
            path='/products/:id'
            component={ProductsBrands}
            isAuthenticated={autenticado}
          />
          <PublicRouter
            exact
            path='/carrito'
            component={ProductsList}
            isAuthenticated={autenticado}
          />

          <PublicRouter
            exact
            path='/Control'
            component={Control}
            isAuthenticated={autenticado}
          />

          <PublicRouter
            exact
            path='/VentasWapizima'
            component={ControlW}
            isAuthenticated={autenticado}
          />
          <PublicRouter
            exact
            path='/VentasMerryColor'
            component={ControlM}
            isAuthenticated={autenticado}
          />
          <PublicRouter
            exact
            path='/VentasAurora'
            component={ControlA}
            isAuthenticated={autenticado}
          />
          <PublicRouter
            exact
            path='/VentasGolden'
            component={ControlG}
            isAuthenticated={autenticado}
          />
          <PublicRouter
            exact
            path='/Ventas'
            component={Completed}
            isAuthenticated={autenticado}
          />
          <PublicRouter
            exact
            path='/Venta/:id'
            component={ShowOrder}
            isAuthenticated={autenticado}
          />
          <PublicRouter exact path='/mis-compras/:id' component={MyOrders} />
          <PrivateRouter
            path='/'
            component={EcommerceRoutes}
            isAuthenticated={autenticado}
          />
        </Switch>
      </Router>
    </>
  );
}
