import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import { Button, Grid, Typography } from "@mui/material";
import TableSales from "./TableSales";
import OrdersContext from "../../Context/Orders/OrdersContext";
import { useContext } from "react";
const Completed = () => {
  const {
    GetAllOrdersCompleted,
    ordersCompleted,
    PdfOrder,
    MakeAsReady,
    SalesOfDay,
  } = useContext(OrdersContext);
  useEffect(() => {
    GetAllOrdersCompleted();
  }, []);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Header />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography
            fontWeight="bold"
            fontFamily="monospace"
            variant="h4"
            textAlign="center"
          >
            Ventas Pagadas
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "center", padding: 2 }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ display: "flex", justifyContent: "end", mb: 2 }}
        >
          <Button variant="contained" size="large" onClick={() => SalesOfDay()}>
            {" "}
            Consultar Ventas
          </Button>
        </Grid>
        <TableSales
          orders={ordersCompleted}
          PdfOrder={PdfOrder}
          MakeAsReady={MakeAsReady}
        />
      </Grid>
    </>
  );
};

export default Completed;
