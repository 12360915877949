import { types } from "../../types";
export default (state, action) => {
  switch (action.type) {
    case types.OBTENER_USUARIO:
      return {
        ...state,
        autenticado: true,
        usuario: action.payload,
        cargando: false,
      };
    default:
      return state;
  }
};
