import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton, Tooltip, Typography } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PriceFormat from "../../utils/FormatCurrency";
import { Link } from "react-router-dom";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableSales({ orders, PdfOrder, MakeAsReady, client }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label='customized table'>
        <TableHead>
          <TableRow>
            <StyledTableCell align='center'>Folio</StyledTableCell>
            {client === true && (
              <StyledTableCell align='center'>Status</StyledTableCell>
            )}
            {client === false && (
              <>
                <StyledTableCell align='center'>Cliente</StyledTableCell>
                <StyledTableCell align='center'>Telefono</StyledTableCell>
              </>
            )}
            <StyledTableCell align='center'>Subtotal</StyledTableCell>
            <StyledTableCell align='center'>Total</StyledTableCell>
            <StyledTableCell align='center'>Opciones</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component='th' scope='row' align='center'>
                {row.id}
              </StyledTableCell>
              {client === true && (
                <StyledTableCell component='th' scope='row' align='center'>
                  <Typography
                    variant='subtitle1'
                    color='success'
                    textAlign='center'
                  >
                    {row.status === 1
                      ? "Recolectado"
                      : row.status === 0 && "No recolectado"}
                  </Typography>
                </StyledTableCell>
              )}
              {client === false && (
                <>
                  <StyledTableCell align='center'>{row.name}</StyledTableCell>
                  <StyledTableCell align='center'>
                    {row.number_phone}
                  </StyledTableCell>
                </>
              )}
              <StyledTableCell align='center'>
                {PriceFormat(row.subtotal)}
              </StyledTableCell>
              <StyledTableCell align='center'>
                {PriceFormat(row.total)}
              </StyledTableCell>
              <StyledTableCell align='center'>
                <>
                  {/* {client === false && ( */}
                  <Link to={`/Venta/${row.id}`}>
                    <IconButton
                      sx={{
                        backgroundColor: "#03a9f4",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#03a9f4",
                          color: "white",
                        },
                      }}
                    >
                      <Tooltip title='Detalle de venta' placement='top'>
                        <VisibilityIcon
                          sx={{ color: "white", fontSize: "30px" }}
                        />
                      </Tooltip>
                    </IconButton>
                  </Link>
                  {/* )} */}
                  <IconButton
                    sx={{
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: 1,
                      "&:hover": { backgroundColor: "red", color: "white" },
                    }}
                    onClick={() => PdfOrder(row)}
                  >
                    <Tooltip title='Descargar PDF' placement='top'>
                      <PictureAsPdfIcon
                        sx={{ color: "white", fontSize: "30px" }}
                      />
                    </Tooltip>
                  </IconButton>

                  {row.status === 0 && client === false && (
                    <IconButton
                      sx={{
                        backgroundColor: "green",
                        color: "white",
                        marginLeft: 2,
                        "&:hover": {
                          backgroundColor: "green",
                          color: "white",
                        },
                      }}
                      onClick={() => MakeAsReady(row)}
                    >
                      <Tooltip title='Marcar como listo' placement='top'>
                        <CheckCircleOutlineIcon
                          sx={{ color: "white", fontSize: "30px" }}
                        />
                      </Tooltip>
                    </IconButton>
                  )}
                </>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
