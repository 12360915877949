import React, { useContext, useEffect } from "react";
import BrandsContext from "../../Context/Brands/BrandsContext";
import { Grid } from "@mui/material";
import BrandCard from "../../components/Cards/BrandCard";
import Layout from "../../components/Layout";
export default function IndexBrands() {
  const { brands, GetBrands } = useContext(BrandsContext);
  useEffect(() => {
    GetBrands();
  }, []);

  return (
    <Layout>
      <Grid container spacing={2} sx={{ backgroundColor: "white", padding: 2 }}>
        {!brands.length
          ? "No hay resultados"
          : brands.map((brand) => (
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3} key={brand.id}>
                <BrandCard brand={brand} key={brand._id} />
              </Grid>
            ))}
      </Grid>
    </Layout>
  );
}
