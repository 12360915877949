import {
  Card,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MethodGet from "../../config/Service";
import { useState } from "react";
import SpinnerComponent from "../../components/loading/SpinnerComponent";
import PriceFormat from "../../utils/FormatCurrency";
import BackIcon from "../../components/icons/BackIcon";
const ShowOrder = (props) => {
  const { id } = props.match.params;
  const [order, saveOrder] = useState([]);
  useEffect(() => {
    let url = `/Sales/${id}`;
    MethodGet(url)
      .then((res) => {
        saveOrder(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);
  const { products, subtotal, total } = order;
  return (
    <Grid container spacing={2}>
      <Header />
      <Grid item xs={10} sm={10} md={10} lg={10} xl={10} mt={15}>
        <Typography fontSize='35px'>Detalle de venta</Typography>
      </Grid>

      <Grid item xs={2} sm={2} md={2} lg={2} xl={2} mt={15}>
        <IconButton onClick={() => props.history.goBack()}>
          <BackIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        {order ? (
          <Card sx={{ padding: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                Folio:{order.Folio}{" "}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                Cliente: {order.nameclient}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                Subtotal: {PriceFormat(Number(subtotal))}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                Descuento: {order.discount} %
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                Total: {PriceFormat(Number(total))}
              </Grid>
              <Divider />
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }} align='left'>
                          C.Barras
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }} align='center'>
                          Producto
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }} align='right'>
                          cantidad
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }} align='right'>
                          P.Unitario
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }} align='right'>
                          subtotal
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }} align='right'>
                          Total
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {products &&
                        products.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component='th' scope='row'>
                              {row.tag}
                            </TableCell>
                            <TableCell align='right'>{row.name}</TableCell>
                            <TableCell align='center'>{row.quantity}</TableCell>
                            <TableCell align='right'>
                              {PriceFormat(Number(row.price_purchase))}
                            </TableCell>
                            <TableCell align='right'>
                              {PriceFormat(
                                Number(row.price_purchase * row.quantity)
                              )}
                            </TableCell>
                            <TableCell align='right'>
                              {PriceFormat(
                                Number(row.final_price * row.quantity)
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Card>
        ) : (
          <SpinnerComponent />
        )}
      </Grid>
    </Grid>
  );
};

export default ShowOrder;
