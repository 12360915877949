import React, { useEffect } from "react";

const NotificationDemo = () => {
  // Solicitar permiso para enviar notificaciones
  const requestNotificationPermission = async () => {
    if (!("Notification" in window)) {
      alert("Este navegador no soporta notificaciones de escritorio.");
      return;
    }

    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Permiso concedido para enviar notificaciones.");
    } else {
      console.log("Permiso denegado para enviar notificaciones.");
    }
  };

  // Función para mostrar la notificación
  const sendNotification = () => {
    if (Notification.permission === "granted") {
      const notification = new Notification("¡Hola desde React!", {
        body: "Esta es una notificación de prueba.",
        icon: "https://via.placeholder.com/100", // Puedes usar un ícono personalizado
      });

      // Evento: Cuando se hace clic en la notificación
      notification.onclick = () => {
        window.focus();
        console.log("Notificación clickeada.");
      };
    } else {
      console.log("No se puede enviar la notificación. Permiso denegado.");
    }
  };

  useEffect(() => {
    // Pedir permiso al cargar el componente
    requestNotificationPermission();
  }, []);

  return (
    <div>
      <h1>Notificaciones en React</h1>
      <button onClick={sendNotification}>Enviar notificación</button>
    </div>
  );
};

export default NotificationDemo;
