import React, { useReducer } from "react";
import AuthContext from "../auth/AuthContext";
import AuthReducer from "../auth/AuthReducer";
import { types } from "../../types";
/**Importar componente token headers */
import tokenAuth from "../../config/TokenAuth";
import MethodGet from "../../config/Service";

const AuthState = (props) => {
  //Agregar state inicial
  const initialState = {
    token: localStorage.getItem("token"),
    autenticado: false,
    usuario: {},
    cargando: false,
    success: false,
    directions: [],
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);
  const usuarioAutenticado = async () => {
    const token = localStorage.getItem("token");

    if (token) {
      tokenAuth(token);
    }

    MethodGet("/admin/auth")
      .then(({ data }) => {
        dispatch({
          type: types.OBTENER_USUARIO,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.LOGIN_ERROR,
        });
      });
  };
  return (
    <AuthContext.Provider
      value={{
        autenticado: state.autenticado,
        success: state.success,
        cargando: state.cargando,
        usuarioAutenticado,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
