import React from "react";
import AuthState from "../src/Context/auth/AuthState";
import AppRouter from "./routes/AppRouter";
import OrdersState from "../src/Context/Orders/OrdersState";
import ProductsState from "./Context/Products/ProductsState";
import BrandsState from "./Context/Brands/BrandsState";
export default function CatalogoApp() {
  return (
    <AuthState>
      <OrdersState>
        <ProductsState>
          <BrandsState>
            <AppRouter />
          </BrandsState>
        </ProductsState>
      </OrdersState>
    </AuthState>
  );
}
